.section {
  background: url('/assets/img/background-tile.png') no-repeat center center;
  background-size: 1366px 1000px;
}
.section-home {
  background-color: #145c88;
  h2 {
    position: relative;
    z-index: 4;
  }
  .img-minoom-home {
    position: relative;
    z-index: 3;
    top: -20px;
  }
}
.section-package-1 {
  background-color: #333;
  .img-minoom-games {
    transform: translate(-10%, 0%) scale(1.4);
    top: -80px;
  }
}
.section-package-2 {
  background-color: #3d3146;
  .img-minoom-packages {
    transform: translate(0%, -4%) scale(1.2);
    top: -80px;
  }
}
#fp-nav {
  ul li a span {
	   width: 12px !important;
	   height: 12px !important;
	   border: 2px solid #fff;
     border-radius: 4px !important;
	    opacity: .6;
      background-color: transparent;
	transition: opacity .2s ease,background .2s ease;
  top: 1px !important; left: 1px !important;
  margin: 0 !important;
  &:hover {
    opacity: 1;
  }
  }
  ul li a.active span {
    background: #fff;
    opacity: 1;
  }
  ul li:hover a.active span {
  }
}
.topleft {
  position: fixed;
  top: 20px; left: 20px;
  z-index: 999;
  width: 18%; height: auto;
  max-width: 200px; max-height: 100px;
  display: flex;
  align-items: center;
  .minoom-logo {
    width: 35%; height: auto;
    max-width: 90px; max-height: 100px;
    img {
      width: 100%; height: auto;
      transition: all .6s cubic-bezier(.17,.67,0,.93);
    }
  }
  .minoom-text {
    display: flex;
    flex-flow: column;
    width: 65%;
    padding-left: 4px;
    .appstore-link {
      margin-top: 8%;
      display: block;
      img {
        width: 100%; height: auto;
        transition: all .6s cubic-bezier(.17,.67,0,.93);
      }
    }
    .minoom-name {
      width: 100%; height: auto;
      transition: all .6s cubic-bezier(.17,.67,0,.93);
    }
  }
}
.fixed-wrapper {
  position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  z-index: 10;
  display: table;
  max-width: 720px;
  width: 100%;
}

.content {
display: table-cell;
vertical-align: middle;
width: inherit;
.content-inside {
  display: block;
  margin: 0 auto;
}
}
.iphone-wrapper {
  position: relative;
  float: left;
  width: 40%;
  .iphone-device {
    width: 100%;
    max-width: 320px;
    height: auto;
  }
}
.content-text {
  position: relative;
  float: right;
  padding-top: 20px;
  p {
    font-size: 5vw;
    line-height: 0.9em;
  }
}
.iphone-screen-wrapper {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 13.46%; left: 12%;
  width: 76.67%; height: 70.8%;
  z-index: 10;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
  .swiper-slide {
    text-align: center;
    background: #fff;
    /* Center slide text vertically */
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
    img {
      width: 100%;
    }
    video {
      width: 100%;
    }
  }
.section-content {
  display: table;
  width: 100%;
  h2 {font-size: 4vw;
  line-height: 1em;
}
  .section-content-wrapper {
    display: table-cell;
    vertical-align: middle;
  .section-content-inside {
    max-width: 720px;
    margin: 0 auto;
    padding-top: 85px;
    .section-content-inside-right {
      position: relative;
      float: right;
      width: 60%;
      img {
        width: 100%;
      }
    }
  }
  }
}
#fp-nav {
  z-index: 100 !important;
}
