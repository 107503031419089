@media only screen and (max-width:480px), screen and (max-height: 680px) {
  .topleft {
    width: 100%; max-width: 100%;
    min-width: 120px;
    top: 8px; left: 8px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    transition: all .6s cubic-bezier(.17,.67,0,.93);
    .minoom-text {
      padding: 0;
      display: flex;
      flex-flow: row;
      width: auto;
      float: none;
      align-items: center;
      .appstore-link {
        margin: 0;
        width: auto;
        display: inline-block;
        img {
          width: auto; max-height: 24px;
          transition: all .6s cubic-bezier(.17,.67,0,.93);
        }
      }
      .minoom-name {
        height: 16px;
        width: auto;
        padding: 0 8px;
        transition: all .6s cubic-bezier(.17,.67,0,.93);
      }
    }
    .minoom-logo {
      max-width: 32px; height: 100%;
      width: auto;
      display: inline-block;
      transition: all .6s cubic-bezier(.17,.67,0,.93);
    }
  }
}
@media only screen and (min-width:320px) and (max-width:480px) {

  .fixed-wrapper {
    display: block;
    top: 220px;
    width: 80%;
    transform: translate(-50%, 0%);
  }
  .content {
    .iphone-wrapper {
      display: block;
      width: 100%;
      float: none;
    }
  }
  .section-content {
    top: 50px;
    position: relative;
    height: 100%;
    display: block;
  .section-content-wrapper {
    vertical-align: top;
    .section-content-inside {
      padding-top: 0 !important;
      h2 {
        font-size: 8vw;
        text-align: center;
        font-weight: 700;
        line-height: 1.2em;
      }
      .section-content-inside-right {
        float: none;
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
.section-home {
  .img-minoom-home {
    width: 75%;
    top: -110px;
  }
}
}
@media only screen and (min-width:480px) {
  .iphone-wrapper {
  }
  .topleft {
    min-width: 100px;
    top: 8px; left: 8px;
    .minoom-text {
      width: 60%;
      .appstore-link {
      }
    }
    .minoom-logo {
      width: 40%;
    }
  }
  .content {
    .iphone-wrapper {
      float: left;
    }
  .content-text {
    width: 50%;
    text-align: left;
  }
}
.section {
  h2 {
    font-size: 8vw;
    transform: scale(.7);
    opacity: 0.2;
  }
  .img-minoom-home {
    transform: scale(.2);
    opacity: 0;
  }
  .img-minoom-games {
    transform: scale(.2);
    opacity: 0;
  }
  .img-minoom-packages {
    transform: scale(.2);
    opacity: 0;
  }
}
.section.active {
  h2 {
    transition: all .6s cubic-bezier(.17,.67,0,.93).6s;
    transform: scale(1);
    opacity: 1;
  }
  .img-minoom-home {
    transition: all .6s cubic-bezier(.17,.67,0,.93) 1s;
    transform: scale(1);
    opacity: 1;
  }
  .img-minoom-games {
    transition: all .6s cubic-bezier(.17,.67,0,.93) 1s;
    transform: scale(1.4);
    opacity: 1;
  }
  .img-minoom-packages {
    transition: all .6s cubic-bezier(.17,.67,0,.93) 1s;
    transform: scale(1.1);
    opacity: 1;
  }
}
.section-content {
.section-content-wrapper {
  .section-content-inside {
    padding-top: 0 !important;

    .section-content-inside-right {
    }
  }
}
}

}
@media only screen and (min-width:768px) {
  .content {
    text-align: left;
  .iphone-wrapper {
  }
}
  .topleft {
    min-width: 120px;
    top: 24px; left: 24px;
  }
  .fixed-wrapper {
    display: table;
  }
  .section-content {
  .section-content-wrapper {
    .section-content-inside {
      padding-top: 0 !important;
      h2 {
        font-size: 4vw;
      }
      .section-content-inside-right {
        width: 60%;
      }
    }
  }
  }

}
@media only screen and (min-width:1024px) {
}
@media only screen and (min-width:1140px) {


}
@media only screen and (min-width:1280px) {

}
@media print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {

}
